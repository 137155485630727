.widget_General_ContentPool_News {
  @media screen and (max-width: 991px) {
    margin-left: -15px;
    margin-right: -15px;

    .swiper {
      padding-left: 15px;
      padding-right: 15px;
    }

    .content-control {
      width: calc(100% - 30px);
      margin: 0 auto 16px;
    }
  }
}

.content-control {
  border-bottom: 2px solid #EAEAEA;
  padding-bottom: 2px;
  margin-bottom: 20px;

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    position: relative;
    color: #605F5F;

    /* başlık altındaki kırmızı çizginin kaybolmasına neden oluyordu
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    */

    &:before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #eb0015;
      left: 0;
      top: calc(100% + 2px);
    }

    &--dark {
      color: #ffffff;

      &:before {
        display: none;
      }
    }

    &--secondary-dark {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
    }
  }

  &__nav-link {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 175% */

    /* Black/500 (Gray) */
    color: #5A5858;
  }

  &__nav-button {
    cursor: pointer;
  }

  &__float-nav {
    position: absolute;
    font-size: 40px;
    top: 50%;
    margin-top: -20px;
    color: #000000;
    display: none;
    cursor: pointer;

    @media screen and (min-width: 1200px) {
      display: block;
    }

    &.swiper-button-disabled {
      color: #C4C4C4;
    }

    &--prev {
      left: -60px;
    }

    &--next {
      right: -60px;
    }
  }
}

.photo-gallery {
  &__prev,
  &__next {
    margin-top: -54px !important;
  }
}

.content-pool {
  &--bg {
    padding-bottom: 55px;
    padding-top: 36px;
    background-color: #f4f4f4;


    .content-pool__item {
      background-color: #f4f4f4;
    }
  }

  &__item-image {
    border-radius: 4px;
    object-fit: cover;
    height: 150px;
    width: 100%;

    &--wide {
      height: 258px;

      @media screen and (min-width: 992px) {
        height: 327px;
      }
    }
  }

  &__item {
    background-color: #FFFFFF;
    /* Black/300 */

    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;

    &--dark {
      padding: 0;
      background-color: transparent;
      border: 0;
    }

    &--wide {
      padding: 0;
      border: 0;
      border-radius: 4px;
      overflow: hidden;
    }
  }

  &__item-picture {
    position: relative;
  }

  &__item-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #FFFFFF;
    font-size: 22px;
  }

  &__play {
    position: absolute;
    opacity: 0.4;
    top: 50%;
    left: 50%;

    margin-left: -19px;
    margin-top: -19px;
    font-size: 38px;

    @media screen and (min-width: 992px) {
      margin-left: -26px;
      margin-top: -26px;
      font-size: 52px;
    }
  }

  &__picture {
    position: relative;
  }

  &__image {
    height: 183px;
    object-fit: cover;

    &--xs-small {
      @media screen and (max-width: 991px) {
        height: 96px;
      }
    }
  }

  &__duration {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
    text-shadow: 0 0 3px #000000;
  }

  &__items {
    position: relative;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;

    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    margin-top: 20px;

    @media screen and (min-width: 992px) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__item-label {
    font-style: normal;
    font-weight: 500;
    /* or 123% */

    color: #000000;

    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    font-size: 18px;
    line-height: 20px;
    height: calc(20px * 3);

    @media screen and (min-width: 992px) {
      -webkit-line-clamp: 2;
      font-size: 15px;
      line-height: 18px;
      height: calc(18px * 2);
    }

    &--dark {
      font-size: 14px;
      line-height: 15px;
      height: calc(15px * 2);
      color: #FFFFFF;

      @media screen and (min-width: 992px) {
        font-size: 20px;
        line-height: 23px;
        height: calc(23px * 2);
      }
    }

    &--wide {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 17px;
      -webkit-line-clamp: 5;
      height: auto;

      @media screen and (min-width: 992px) {
        font-size: 19px;
        line-height: 19px;
        -webkit-line-clamp: 4;
        height: auto;
      }
    }

    &--foto {
      @media screen and (max-width: 991px) {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        -webkit-line-clamp: 2;
        height: calc(23px * 2);
      }
    }
  }

  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height, or 175% */
    /* Black/500 (Gray) */
    color: #959393;
  }

  &--dark {
    background-color: #1C1C1C;
    padding-bottom: 34px;
    padding-top: 29px;

    @media screen and (min-width: 992px) {
      padding-bottom: 64px;
      padding-top: 44px;
    }

    .content-pool__item-label,
    .content-control__label {
      color: #fff;
    }
  }

  &__float--wide {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(360deg, #000000 -9.09%, rgba(0, 0, 0, 0) 58.12%);
    padding: 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 4px;

    //@media screen and (min-width: 992px) {
    //  padding: 20px 27px;
    //}

    .content-pool__item-label {
      color: #ffffff;
    }
  }

  &.photo-gallery {
    @media screen and (max-width: 991px) {
      .content-control__label:before {
        display: none;
      }
      .content-control {
        border-bottom: 0;
      }
    }
  }
}

.content-control__next,
.content-control__prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 30px;
  margin-top: -15px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: 50%;
  background-repeat: no-repeat;
  font-size: 30px;
  color: #605f5f;

  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
}

.content-control__next {
  right: -40px;
}

.content-control__prev {
  left: -40px;
}

.content-control__next.swiper-button-disabled,
.content-control__prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none;
}

.news-list {
  &__heading {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 27px;
    color: #605F5F;
    margin-bottom: 20px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    color: #3F3B3B;

    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;

    @media screen and (min-width: 992px) {
    }
  }

  &__intro {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #939393;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__date {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #595959;
    margin-bottom: 5px;

    @media screen and (min-width: 992px) {
    }
  }

  &__image {
    width: 100%;
    border-radius: 4px;

    &--left {
      width: 100%;

      @media screen and (min-width: 992px) {
        width: 382px;
        min-width: 382px;
        max-width: 382px;
      }
    }
  }

  &__item {
    margin-bottom: 30px;
  }

  &__play-icon {
    color: #EB0015;
    font-size: 10px;
  }

  &__image-wrapper {
    position: relative;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -26px;
    margin-top: -26px;
    font-size: 52px;
    opacity: 0.4;

    &--small {
      margin-left: -15px;
      margin-top: -15px;
      font-size: 30px;

      .icon-play-o .path1:before {
        color: #000000;
      }
    }
  }

  &__item--dark {
    background-color: #000000;
    .sidebar-news__date {
      color: #605F5F;
    }
    .sidebar-news__label {
      color: #ffffff;
    }
  }
}

